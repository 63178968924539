<template>
  <div>
    <register-header></register-header>
    <div class="container">
      <div class="login-wrapper">
        <div class="login-header">{{ $t('home.left.signUp') }}</div>
        <div class="form-wrapper">
          <input v-model="user.username" type="text" name="username" :placeholder="$t('home.left.register.username')"
                 class="input-item">
          <div class="password-input-wrapper">
            <input v-model="user.password" :type="showPassword ? 'text' : 'password'" name="password"
                   :placeholder="$t('home.left.register.password')" class="input-item">
            <b @click="showPassword = !showPassword" class="fa"
               :class="showPassword ? 'fa-eye-slash' : 'fa-eye'">
            <svg t="1690381469047" class="icon" viewBox="0 0 1024 1024" version="1.1"
                 xmlns="http://www.w3.org/2000/svg" p-id="7308" width="24" height="24">
              <path
                  d="M343.637333 435.925333A189.738667 189.738667 0 0 0 341.333333 465.493333c0 96.938667 72.96 174.762667 161.450667 175.36 89.258667-0.64 162.005333-78.293333 162.005333-175.36 0-14.506667-1.621333-28.629333-4.736-42.24 34.133333 19.456 64 45.525333 87.978667 76.373334-15.786667 126.890667-118.528 225.664-245.248 226.56-119.125333-0.853333-217.6-89.258667-241.322667-206.165334a300.288 300.288 0 0 1 82.176-84.053333z m609.536 6.229334l-62.933333 57.642666C766.378667 364.672 659.968 298.666667 512 298.666667c-144.341333 0-255.146667 69.589333-378.112 200.362666l-62.165333-58.453333C209.322667 294.229333 338.090667 213.333333 512 213.333333c177.322667 0 302.72 77.738667 441.173333 228.821334z"
                  fill="#000000" p-id="7309"></path>
            </svg>
            </b>
          </div>
          <div @click="register" class="btn">{{ $t('channel.commentSubmit') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import registerHeader from '@/pages/home/components/Header'
import storageService from '../../service/storageService'
import activeInstance from '@/router/api'

export default {
  name: 'Register',
  components: {
    registerHeader
  },
  data() {
    return {
      showPassword: false,
      user: {
        username: '',
        password: '',
      }
    }
  },
  methods: {
    register() {
      activeInstance.post("/api/v1/auth/register", this.user).then(res => {
        if (res.data.code !== 0) {
          this.$message.error(res.data.message)
          return
        }
        // 保存token，console.log(res.data)
        storageService.set(storageService.USER_TOKEN, res.data.data.token)
        // 保存用户信息
        // 将 userinfo 转换成字符串
        const userinfoStr = JSON.stringify(res.data.data.userinfo);
        storageService.set(storageService.USER_INFO, userinfoStr)
        this.$router.replace({name: 'Home'})

      }).catch(err => {
        // 错误提示
        console.log(err)
        this.$message.error("SYSTEM ERROR")
      })
    }
  },
  mounted: function () {
    // 自动触发是否已经登入的函数
    if (localStorage.getItem('aikan_user_token') && localStorage.getItem('aikan_user_info')) {
      this.$router.replace({name: 'Home'})
    }
  }
}
</script>

<style scoped>

.btn {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  height: 35px;
  line-height: 35px;
}

.login-wrapper {
  font-family: 'Open Sans Light';
  letter-spacing: .05em;
  /*background-color: #fff;*/
  width: 250px;
  padding: 0 50px;
  position: absolute;
  left: 50%;
  border-radius: 15px;
  top: 40%;
  transform: translate(-50%, -50%);
}

.login-wrapper .login-header {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  line-height: 200px;
}

.login-wrapper .form-wrapper .input-item {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  border: none;
  padding: 10px 0;
  border-bottom: 2px solid rgb(128, 125, 125);
  font-size: 15px;
  outline: none;
}

.login-wrapper .form-wrapper .input-item::placeholder {
  text-transform: uppercase;
}

.login-wrapper .form-wrapper .btn {
  text-align: center;
  padding: 5px;
  margin-top: 40px;
  border-radius: 25px;
  background-image: linear-gradient(to right, #225ffd, #225ffd);;
  color: #fff;
}

.login-wrapper .msg {
  text-align: center;
  line-height: 80px;
}

.login-wrapper .msg a {
  text-decoration-line: none;
  color: #a6c1ee;
}
</style>
